import { Link } from "gatsby"
import React from "react"
import logo from "../images/pp-icon.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faTasks, faUsers, faBriefcase,faRss,faComment } from '@fortawesome/free-solid-svg-icons'

class HeaderServices extends React.Component{
  render(){
    return(
      <header>
        <div className="header fixed">
          <nav>
            <ul className="navbar">
                <li>
                  <Link to="/"  activeClassName="active">
                    <h3>
                    Inicio
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/#servicios" activeClassName="active">
                    <h3>
                    Servicios
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/#clientes" activeClassName="active">
                    <h3>
                    Clientes
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img src={logo} className="logo" alt="PyP Asesoría Integral"/>
                  </Link>
                </li>
                <li>
                  <Link to="/nosotros" activeClassName="active">
                    <h3>
                    Nosotros
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/#noticias" activeClassName="active">
                    <h3>
                    Noticias
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/#contacto" activeClassName="active">
                    <h3>
                    Contacto
                    </h3>
                  </Link>
                </li>
              </ul>
          </nav>
        </div>
        <div className="mobile-bottom">
          <ul>
            <li>
              <Link to="/">
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </li>
            <li>
              <Link to="/#servicios">
                <FontAwesomeIcon icon={faTasks} />
              </Link>
            </li>
            <li>
              <Link to="/#clientes">
                <FontAwesomeIcon icon={faBriefcase} />
              </Link>
            </li>
            
            <li>
              <Link to="/nosotros">
                <FontAwesomeIcon icon={faUsers} />
              </Link>
            </li>
            <li>
              <Link to="/#noticias">
                <FontAwesomeIcon icon={faRss} />
              </Link>
            </li>
            <li>
              <Link to="/#contacto">
                <FontAwesomeIcon icon={faComment} />
              </Link>
            </li>
          </ul>
        </div>

      </header>
    )
  }
}


export default HeaderServices


